<template>
  <div>
    <page-title class="padding-left-layout padding-top-layout" :title='$t("dashboard_detail.title_emissions_by_location")'/>
    <div class="fix-top" id="headFix" :style="{'left': headerLeft}">
      <div class="container pl-40-px list-filter">
        <div class="row filter-items">
          <mix-label-pull-down-menu
            type="scope_select"
            v-model="scope_select"
            :label="scopeText"
            :items="scope_list"
            :initialValue="scope_select"
            :placeHolderText="placeholderText"
            width="100%"
            class-list="one-line border-left border-right"
            @updateData="updateData"
            idBox="1"
          />
          <mix-label-pull-down-menu
            type="type_select"
            :label="typeText"
            v-model="type_select"
            :items="type_list"
            :initialValue="type_select"
            width="100%"
            class-list="one-line border-right"
            @updateData="updateData"
            idBox="2"
          />
          <mix-label-pull-down-menu
            type="period_select"
            :label="periodText"
            v-model="period_select"
            :items="period_list"
            :initialValue="period_select"
            width="100%"
            min-width="160px"
            class-list="one-line border-right"
            @updateData="updateData"
            idBox="3"
          />
        </div>
      </div>
    </div>
    <div class="emission-by-period">
      <div
        class="category-table main-table custom-table center-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="dbCustomViews"
          :init-grid="flexInitialized"
          :grid-columns="gridColumns"
          :totalData="totalData - 1"
          :rowFocus="2"
          :showFocus="false"
          :allow-add-new="false"
          :lastedRow="lastedRow"
          :isSetting="true"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ROUTES } from "@/router/constants";
import DataTable from "@/components/category/data-table";
import { CollectionView } from "@mescius/wijmo";
import { formatDateTime } from "@/utils/datetimeFormat";
import PageTitle from "@/components/pageTitle/pageTitle";
import { getStartMonth } from "@/api/duration";
import { getEmissionsByPeriod, getDetailScopeBranch } from "@/api/dashboard";
import { numberFormat } from "@/filters/number";
import { KEYS_CODE } from "@/constants/keyboard";
import MixLabelPullDownMenu from "@/components/pulldown/MixLabelPullDownMenu";
import { $_helper_isNumberType, formatValue, math } from '@/concerns/newRegisterData/wijmo.helper';
import { preparePhysicalList, convertMonthCalendar } from '@/utils/registerData';
import { addThoundSandComma } from '@/utils/convertNumber';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import { formatValueUnit } from '@/concerns/chart';
export default {
  components: {
    DataTable,
    PageTitle,
    MixLabelPullDownMenu
  },
  data() {
    return {
      diffText           : this.$t('list_emissions.title_scope_others'),
      selectionButtonText: this.$t('customized_db_management.button_choice'),
      totalData          : null,
      context            : null,
      items              : null,
      activeRow          : null,
      types              : {
        category: "Categories",
        scope   : "Scopes"
      },
      dbCustomsFlex      : null,
      selectedItem       : "",
      gridColumns        : [],
      dbCustoms          : null,
      dbCustomViews      : null,
      unit               : "t-CO2/含有量t,t-CO2/kL,t-CO2/tkm,t-CO2/個,t-CO2/台".split(","),
      scope              : [1, 2, 3],
      category           : [1, 2, 3, 4, 5, 6],
      restoreSelection   : null,
      pastingData        : null,
      scope_list         : [
        {
          name : this.$t('dashboard_detail.label_all'),
          value: ""
        },
        {
          name : `${this.$t('dashboard_main.label_scope')} 1`,
          value: 1
        },
        {
          name : `${this.$t('dashboard_main.label_scope')} 2`,
          value: 2
        },
        {
          name : `${this.$t('dashboard_main.label_scope')} 3`,
          value: 3
        }
      ],
      scope_select       : "",
      type_select        : "",
      type_list          : [
        {
          name : this.$t("dashboard_detail.label_by_registration_year"),
          value: 1
        },
        {
          name : this.$t("dashboard_detail.label_semi-annual"),
          value: 3
        },
        {
          name : this.$t("dashboard_detail.label_quarterly"),
          value: 4
        },
        {
          name : this.$t("dashboard_detail.label_by_months"),
          value: 2
        },
      ],
      period_select      : "",
      period_list        : [],
      dataCategory       : [],
      width1             : this.getWidth() ? "160px" : "100%",
      width2             : this.getWidth() ? "300px" : "100%",
      branch             : [],
      resizeTimer        : "",
      lastedRow          : {},
      listLayers         : [],
      layerIndex         : {
        1: "business_name",
        2: "country",
        3: "layer_3",
        4: "layer_4",
        5: "layer_5",
        6: "layer_6"
      },
      isEnableScope3     : false,
      gotData            : false,
      selectedFilterColumn: null,
      startMonth: null,
      order: null,
    };
  },
  async mounted() {
    this.branch = this.$store.state.dashboard.branchIds;
    this.scope_select = this.$route.query.scope == undefined ? "" : Number(this.$route.query.scope);
    this.type_select  = Number(this.$route.query.type);
    this.order = Number(this.$route.query.order);
    await getStartMonth(this.contractor).then(res => {
      this.startMonth = res.data.start_month;
      if (this.type_select !== 2) {
        this.preparePeriodList();
      }
    })
    if (this.type_select === 1) {
      this.period_select = Number(this.$route.query.year);
    } else if (this.type_select === 2) {
      this.period_select = {
        month: Number(this.$route.query.month),
        year: Number(this.$route.query.year),
      }
    } else {
      this.period_select = `${this.order}-${Number(this.$route.query.year)}`;
    }
    this.handleWindowResize();
    let items = [
      {
        text    : this.$t('dashboard_detail.hyperlink_home'),
        disabled: false,
        href    : ROUTES.DASHBOARD
      },
      {
        text    : this.$t('dashboard_detail.label_emissions_by_site'),
        disabled: true,
        href    : ROUTES.DASHBOARD
      }
    ];
    this.updateBreadCrumb(items);
    this.width1 = this.getWidth() ? "160px" : "100%";
    this.width2 = this.getWidth() ? "300px" : "100%";
  },
  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen"]),
    ...mapState('settingsDashboard', ['itemsSettings']),
    scopeText() {
      return this.$t("dashboard_detail.pulldown_select_scope");
    },
    typeText() {
      return this.$t("dashboard_detail.pulldown_select_display");
    },
    periodText() {
      return this.$t("dashboard_detail.pulldown_select_period");
    },
    placeholderText() {
      return this.$t("dashboard_detail.label_all");
    },
    headerLeft() {
      let leftWidth = '0px';
      if(!this.checkMobile()) {
        leftWidth = this.isExpand ? '236px' : '73px';
      }
      return leftWidth
    },
    getUnitName() {
      return this.itemsSettings[this.contractor]?.unitName || 't-CO2';
    },
    getType() {
      return this.itemsSettings[this.contractor]?.type || 'market';
    }
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    checkMobile() {
      return window.innerWidth < 1024;
    },
    getGridColumn() {
      const headerUnit = this.$i18n.locale === 'ar-AE' && this.getUnitName === 'kg-CO2' ? this.$t("dashboard_detail.table_emissions_kg_co2") : this.$t("dashboard_detail.tabel_emissions", { unit: this.getUnitName })
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header      : this.$t("dashboard_detail.tabel_organization_division"),
          binding     : "organizational_division",
          allowSorting: false,
          isReadOnly  : true,
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_organization_division")),
          maxWidth    : 400,
          wordWrap    : true,
          cssClassAll : "no-tooltip single-row"
        },
        {
          header      : this.$t("dashboard_detail.tabel_corporate_name"),
          binding     : "company_name",
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_corporate_name")),
          maxWidth    : 400,
          allowSorting: false,
          isReadOnly  : true,
          align       : "left",
          wordWrap    : true,
          cssClassAll : "no-tooltip single-row"
        },
        ...this.listLayers || [],
        {
          header      : headerUnit,
          binding     : "total_emission_branch",
          minWidth    : getWidthByTextContent(headerUnit),
          maxWidth    : 960,
          allowSorting: false,
          isReadOnly  : true,
          align       : "right",
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        {
          header      : this.$t("dashboard_detail.tabel_ratio_to_all_locations"),
          binding     : "rate_all",
          minWidth    : getWidthByTextContent(this.$t("dashboard_detail.tabel_ratio_to_all_locations")),
          width: '*',
          allowSorting: false,
          isReadOnly  : true,
          align       : "right",
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        }
      ];
    },
    defineTable() {
      this.gridColumns   = this.getGridColumn();
      const dataTable    = this.dbCustoms?.map((item, index) => item && {
        ...item,
        index: index + 1
      });
      const formatItems = ['total_emission_branch', 'rate_all'];
      this.dbCustomViews = new CollectionView(dataTable, {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if (this.selectedFilterColumn === 'rate_all') {
            const valueA = a.split('%')[0].trim();
            const valueB = b.split('%')[0].trim();
            if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
              const numA = Number(formatValue(valueA));
              const numB = Number(formatValue(valueB));
              return numA - numB;
            }
            return null;
          } else {
            if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
              const numA = Number(formatValue(a));
              const numB = Number(formatValue(b));
              return numA - numB;
            }
            return null;
          }
        }
      });
      this.dbCustomsFlex.columnGroups = this.getGridColumn();
      this.dbCustomViews.currentItem = null;
      setMinMaxSizeColumns(this.dbCustomsFlex, dataTable);
    },
    flexInitialized(flexgrid) {
      this.dbCustomsFlex = flexgrid;
      flexgrid.hostElement?.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 1);
              flexgrid.selection     = cellRange;
            }
          }
        },
        false
      );
    },
    preprocessFetchedData(data) {
      let preprocessedData = [];
      const isTco2Unit = this.getUnitName === 't-CO2';
      data?.forEach((element) => {
        const formattedEmission = formatValueUnit(element.total_emission_branch, isTco2Unit, true);
        let itemData = {
          id                       : element.id,
          business_name            : element.business_name,
          company_name             : element.company_name,
          country                  : element.country,
          organizational_division  : element.organizational_division,
          rate_all                 : element.rate_all + " %",
          total_emission_all_branch: element.total_emission_all_branch,
          total_emission_branch    : addThoundSandComma(formattedEmission, '', false, true),
          layer_3                  : element.layer_3,
          layer_4                  : element.layer_4,
          layer_5                  : element.layer_5,
          layer_6                  : element.layer_6
        };
        preprocessedData.push(itemData);
      });
      return preprocessedData;
    },

    async fetchData() {
      this.lastedRow = {};
      this.actionUpdateIsLoadingTable(true)
      try {
        this.gotData    = false;
        const dbCustoms = await this.callApiFetchData();
        if (dbCustoms) {
          this.gotData = true;
          if (!dbCustoms.display_scope_3 && parseInt(this.scope_select) === 3) {
            await this.$router.push("404");
          }
          if (dbCustoms.display_scope_3) {
            this.isEnableScope3 = true;
            // let scope3 = {
            //   name : `${this.$t('dashboard_main.label_scope')} 3`,
            //   value: 3
            // }
            // if (this.scope_list.length <= 3){
            //   this.scope_list.push(scope3);
            // }
          } else {
           const indexScope3 = this.scope_list.findIndex(item => item.value === 3);
           if(indexScope3) this.scope_list.splice(indexScope3, 1);
          }
          if ((dbCustoms.display_scope_3 && this.scope_list.length <= 4) || (!dbCustoms.display_scope_3 && this.scope_list.length <= 3)){
            let scope4 = {
              name : this.$t('list_emissions.title_scope_others'),
              value: 4
            }
            this.scope_list.push(scope4);
          }
          this.totalData  = dbCustoms.length;
          this.dbCustoms  = await this.preprocessFetchedData(dbCustoms.detail_scope_branch);
          this.listLayers = dbCustoms.layer?.map(item => {
            return {
              header      : item.layer_name,
              visible     : true,
              binding     : this.layerIndex[item.layer_index],
              minWidth    : 90,
              maxWidth    : 980,
              allowSorting: false,
              isRequired  : false,
              wordWrap    : true,
              isReadOnly  : true,
              cssClassAll : "single-row"
            };
          });
        }
        this.actionUpdateIsLoadingTable(false)
      } catch (error) {
        this.actionUpdateIsLoadingTable(false)
        console.log(error);
      }
    },
    async callApiFetchData() {
      const isMarket = (this.getType === 'market') ? 1 : 0;
      if (this.type_select == 1) {
        // year
        if (this.type_select && this.period_select && this.branch) {
          return await getDetailScopeBranch({
            type       : this.type_select,
            year: this.period_select,
            branch_id  : this.branch,
            scope      : this.scope_select,
            contractor_id: Number(this.contractor),
            isMarket
          });
        }
      } else if (this.type_select == 2) {
        // month
        const monthVal = Number(this.period_select.month) || Number(this.$route.query.select_period_month) || Number(this.$route.query.month);
        if(!monthVal) return
        return await getDetailScopeBranch({
          type     : this.type_select,
          month    : monthVal,
          year     : this.period_select.year || this.$route.query.select_period_year || this.$route.query.year,
          branch_id: this.branch,
          scope    : this.scope_select,
          contractor_id: Number(this.contractor),
          isMarket
        });
      } else  {
        if (!this.period_select) return;
        return await getDetailScopeBranch({
          type     : this.type_select,
          order    : this.period_select.split('-')[0],
          year     : this.period_select.split('-')[1],
          branch_id: this.branch,
          scope    : this.scope_select,
          contractor_id: Number(this.contractor),
          isMarket
        });
      }
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    getDurationMonth() {
      const contractor_id = this.$store.state.userData.contractor;
      const type          = 1;
      const params        = {
        contractor_id,
        type
      };
      getEmissionsByPeriod(params).then((res) => {
        // this.branch = res.branch_ids;
        let charactor;
        const localeLang = this.$i18n.locale
        switch (localeLang) {
          case 'en':
          case 'vi':
            charactor = ' ';
            break;
          case 'ja':
            charactor = '';
            break;
          default:
            charactor = '/'
            break;
        }
        this.period_list = res.data[this.getType].sort((a, b) => {
          if (a.year === b.year) {
            return a.month - b.month;
          } else {
            return b.year - a.year;
          }
        }).map((item) => {
          let name = ''
          if(localeLang !== 'vi') {
            name = this.$t('register_data.year_calendar_select', {year: item.year}) + charactor + this.$t('register_data.month_select', { month: convertMonthCalendar(numberFormat(item.month))})
          } else {
            name = 'Tháng ' + this.$t('register_data.month_select', { month: convertMonthCalendar(numberFormat(item.month))}) + ' năm ' + this.$t('register_data.year_calendar_select', {year: item.year})
          }
          return {
            value: {
              month: Number(item.month),
              year: item.year
            },
            name : name
          };
        });
      });
    },
    getWidth() {
      return window.innerWidth >= 768;
    },
    onWindowResize() {
      window?.addEventListener("resize", this.handleWindowResize);
    },
    offWindowResize() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
    handleWindowResize(_) {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.width1 = this.getWidth() ? "160px" : "100%";
        this.width2 = this.getWidth() ? "300px" : "100%";
      }, 100);
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    preparePeriodList() {
      // this.period_list
      if (this.type_select == 1) {
        this.period_list = preparePhysicalList('year', this.startMonth);
      } else if (this.type_select == 2) {
        this.getDurationMonth();
      } else if (this.type_select == 3) {
        this.period_list = preparePhysicalList('half-year', this.startMonth);
      } else  {
        this.period_list = preparePhysicalList('quarter-year', this.startMonth);
      }
    },
    updateData(data, type) {
      this[type] = data;
    }
  },
  watch   : {
    scope_select() {
      if (!this.isEnableScope3 && this.gotData && parseInt(this.scope_select) === 3) {
        this.$router.push("404");
      }
      if(!this.period_select && this.type_select === 3) return
      this.fetchData().then(() => {
        this.defineTable();
      });
    },
    type_select() {
      this.preparePeriodList();
      this.period_select = "";
    },
    period_select() {
      this.fetchData().then(() => {
        this.defineTable();
      });
    }
  },
  destroyed() {
    this.offWindowResize();
  }
};
</script>

<style scoped lang="scss">
.padding-top-layout {
  padding-top: 30px !important;
}
.emission-select {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0;
  filter: drop-shadow(0px 65px 110px rgba(160, 181, 186, 0.0767396)) drop-shadow(0px 14.5186px 24.5699px rgba(160, 181, 186, 0.11326)) drop-shadow(0px 4.32257px 7.31511px rgba(160, 181, 186, 0.19));

  margin-bottom: 40px;

  .block {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    margin-bottom: 8px;

    .title {
      font-family: 'Source Han Sans' !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px !important;
      line-height: 24px;
      letter-spacing: 0.03em !important;

      width: 100px;
      color: #404d50;
      margin: 8px 8px 8px 0;
    }
  }

  .hr-block {
    display: none;

    .hr-style {
      width: 20px;
      align-self: center;
      height: 1px;
      background: rgba(42, 42, 48, 0.1);
      border: none;
      margin-top: auto;
      margin-bottom: auto;
      flex-grow: unset;
    }
  }
}

.emission-by-period {
  .custom-table {
    &.full-screen {
      top: 127px
    }
  }
}

@include tablet {
  .emission-title {
    padding: 40px 40px 0;
  }

  .emission-select {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 40px 0;
    filter: drop-shadow(0px 65px 110px rgba(160, 181, 186, 0.0767396)) drop-shadow(0px 14.5186px 24.5699px rgba(160, 181, 186, 0.11326)) drop-shadow(0px 4.32257px 7.31511px rgba(160, 181, 186, 0.19));
    margin-bottom: 0;

    .block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      margin-bottom: 48px;

      .title {
        font-family: 'Source Han Sans' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 24px;
        letter-spacing: 0.03em !important;

        color: #404d50;
        margin: 0 0 8px;
      }
    }

    .hr-block {
      height: 40px;
    }
  }

  .emission-by-period {
    margin: 0 40px;
  }
}

.source-column {
  word-wrap: break-word;
}

.wj-flexgrid .wj-cell:not([wj-state-measuring]) button.wj-cell-maker {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 106px;
  height: 24px;
  background: #e4e6e7;
  border-radius: 4px;
  margin: 4px;

  /* Table/CellBtn */

  font-family: 'Source Han Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #99a6a9;
}

#headFix {
  transition: 0.1s ease-out;
  transition-duration: 0.1s;
}

#headFix.fix-top {
  width: 100%;
  display: block;
  z-index: 99;
  position: fixed;
  top: 84px;
  background: $monoOffWhite;
  right: 0;
  max-width: 100%;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  box-shadow: 0 4.322566509246826px 7.315112113952637px 0 rgba(160, 181, 186, 0.19),
  0 14.518600463867188px 24.5699405670166px 0 rgba(160, 181, 186, 0.11),
  0 65px 110px 0 rgba(160, 181, 186, 0.08);
  padding-left: 20px;
  padding-right: 20px;
  .border-right {
    border-right: 1px solid rgba(42, 42, 48, 0.1);
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .list-filter {
    overflow: auto;
    white-space: nowrap;
  }

  .list-filter::-webkit-scrollbar {
    height: 7px;
  }

  .list-filter::-webkit-scrollbar-thumb:horizontal{
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background: #bfd4d9;
  }

  /* Track */
  .list-filter::-webkit-scrollbar-track:horizontal {
    background: #e3eded;
  }

  /* Handle on hover */
  .list-filter::-webkit-scrollbar-thumb:horizontal:hover {
    background: #7a8b8d;
  }

  .filter-items {
    display: inline-block;
    vertical-align: top;
    height: 43px;
  }

  .one-line {
    display: inline-block;
  }
}

@include desktop {
  #headFix.fix-top {
    left: 236px;
    padding-left: 0;
  }

  .padding-top-layout {
    padding-top: 76px !important;
  }

  .border-left {
    border-left: 1px solid rgba(42, 42, 48, 0.1);
  }

  .pl-40-px {
    padding-left: 40px;
  }

  .list-filter {
    overflow: hidden !important;

    &:hover {
      overflow-x: auto !important;
    }
  }
}
</style>
