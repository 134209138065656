import { render, staticRenderFns } from "./emission-by-base-period.vue?vue&type=template&id=fa589c32&scoped=true&"
import script from "./emission-by-base-period.vue?vue&type=script&lang=js&"
export * from "./emission-by-base-period.vue?vue&type=script&lang=js&"
import style0 from "./emission-by-base-period.vue?vue&type=style&index=0&id=fa589c32&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa589c32",
  null
  
)

export default component.exports